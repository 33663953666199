<template>
  <div v-if="!loading">
    <router-view />
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/theme.scss';
</style>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';

@Component({
  components: {},
})
export default class App extends ComponentBase {
  public loading = true;

  // load initial locales and navigation data
  public async created() {
    try {
      this.loading = true;
      await this.initLocales();
      await this.initCurrentLocale();
      if (this.$route.name === 'home') {
        await this.$router.replace({ name: 'home.locale', params: { locale: this.AppState.currentLocale?.code } });
      }
    } catch (err) {
      await this.$router.replace({ name: 'maintenance' });
      document.querySelector('#app')?.classList.remove('loading');
    } finally {
      this.loading = false;
    }
  }
}
</script>
