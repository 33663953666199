<!-- eslint-disable vue/max-len -->
<template>
  <section id="contact" class="relative pt-10 flex items-center justify-center">
    <div class="absolute left-0 top-0 -z-[1] h-full w-full" />
    <div class="absolute left-0 top-0 -z-[1] h-1/2 w-full bg-primary lg:h-[45%] xl:h-1/2" />
    <div class="container px-4" v-if="global">
      <div class="-mx-4 flex flex-wrap items-center">
        <div class="w-full px-4 lg:w-7/12 xl:w-8/12">
          <div class="ud-contact-content-wrapper">
            <div class="ud-contact-title mb-8 lg:mb-[120px]">
              <span class="mb-6 block text-base font-medium uppercase text-white">
                {{global.footerContactSectionSubTitle}}
              </span>
              <h2 class="max-w-[260px] text-[35px] font-semibold leading-[1.14] text-white">
                {{global.footerContactSectionTitle}}
              </h2>
            </div>
            <div class="mb-12 flex flex-wrap justify-between lg:mb-0">
              <div class="mb-8 flex w-[330px] max-w-full">
                <div class="mr-6 text-[32px] text-white lg:text-black">
                  <fa-icon icon="location-dot" />
                </div>
                <div>
                  <p class="mb-[18px] text-lg font-semibold lg:text-black text-white">
                    {{global.footerLocationTitle}}
                  </p>
                  <p class="text-base lg:text-black text-white">
                    {{global.footerLocation}}
                  </p>
                </div>
              </div>
              <div class="mb-8 flex w-[330px] max-w-full">
                <div class="mr-6 text-[32px] text-white lg:text-black">
                  <fa-icon icon="envelope" />
                </div>
                <div>
                  <p class="mb-[18px] text-lg font-semibold lg:text-black text-white">
                    {{global.footerEmailTitle}}
                  </p>
                  <div class="lg:text-black text-white" v-html="marked(global.footerEmailContent)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full px-4 lg:w-5/12 xl:w-4/12">
          <div id="footer-contact-header" class="rounded-lg bg-white px-8 py-10 shadow-testimonial dark:bg-dark-2 dark:shadow-none sm:px-10 sm:py-12 md:p-[60px] lg:p-10 lg:px-10 lg:py-12 2xl:p-[60px]">
            <a name="footer-contact-a" />
            <h3 class="mb-8 text-2xl font-semibold text-black md:text-[28px] md:leading-[1.42]">
              {{global.footerFormTitle}}
            </h3>
            <form @submit.prevent="submit" v-if="!success">
              <div class="mb-[22px]">
                <label for="fullName" class="mb-4 block text-sm text-body-color dark:text-dark-6">{{global.footerFormName}} <span class="text-red-600">*</span></label>
                <input :disabled="loading" type="text" name="fullName" v-model="form.name" :placeholder="global.footerFormNamePlaceholder" class="w-full border-0 border-b border-[#f1f1f1] bg-transparent pb-3 text-body-color placeholder:text-body-color/60 focus:border-primary focus:outline-none dark:border-dark-3 dark:text-dark-6">
              </div>
              <div class="mb-[22px]">
                <label for="email" class="mb-4 block text-sm text-body-color dark:text-dark-6">{{global.footerFormEmail}} <span class="text-red-600">*</span></label>
                <input :disabled="loading" type="email" name="email" v-model="form.email" :placeholder="global.footerFormEmailPlaceholder" class="w-full border-0 border-b border-[#f1f1f1] bg-transparent pb-3 text-body-color placeholder:text-body-color/60 focus:border-primary focus:outline-none dark:border-dark-3 dark:text-dark-6">
              </div>
              <div class="mb-[22px]">
                <label for="phone" class="mb-4 block text-sm text-body-color dark:text-dark-6">{{global.footerFormPhone}}</label>
                <input :disabled="loading" type="text" name="phone" v-model="form.phone" :placeholder="global.footerFormPhonePlaceholder" class="w-full border-0 border-b border-[#f1f1f1] bg-transparent pb-3 text-body-color placeholder:text-body-color/60 focus:border-primary focus:outline-none dark:border-dark-3 dark:text-dark-6">
              </div>
              <div class="mb-[30px]">
                <label for="message" class="mb-4 block text-sm text-body-color dark:text-dark-6">{{global.footerFormMessage}} <span class="text-red-600">*</span></label>
                <textarea :disabled="loading" name="message" v-model="form.message" rows="2" :placeholder="global.footerFormMessagePlaceholder" class="w-full resize-none border-0 border-b border-[#f1f1f1] bg-transparent pb-3 text-body-color placeholder:text-body-color/60 focus:border-primary focus:outline-none dark:border-dark-3 dark:text-dark-6" />
              </div>
              <div class="mb-0">
                <button :disabled="!form.name || !form.email || !form.message || loading" type="submit" class="disabled:cursor-not-allowed disabled:bg-primary-50 inline-flex items-center justify-center rounded-md bg-primary px-10 py-3 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-primary-400">
                  <fa-icon v-if="loading" icon="circle-notch" class="mr-1 fa-spin" />
                  {{global.footerFormSend}}
                </button>
              </div>
            </form>
            <div v-if="success">
              <h3 class="mb-8 text-xl font-semibold text-black text-center">
                {{global.footerFormSuccessTitle ?? 'Thank you!'}}
              </h3>
              <p class="text-center">
                {{global.footerFormSuccessContent ?? 'We will contact you shortly'}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';

@Component({
  components: {},
})
export default class FooterContact extends ComponentBase {
  public form = {
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  public success = false;

  public loading = false;

  public get global() {
    return this.AppState.global;
  }

  public async submit() {
    try {
      this.loading = true;
      await this.strapi.post('/contact-forms', {
        data: this.form,
      });
      this.success = true;
    } catch (err) {
      console.error('err', err);
    } finally {
      this.loading = false;
    }
  }
}

</script>
