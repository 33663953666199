import axios, { AxiosInstance } from 'axios';
import { setupCache } from 'axios-cache-interceptor';

import GeneralConfig from '@/config/general';

// we use 2 sec so if we have same resources on same page, we wont re-fetch those
// if longer caches to be used, cache control must be implemented. Eg. if new resource is created or existing modified
// then all related caches must be revoked.
// @TODO: implement cache control to blueprint components
const CACHE_TTL = 1000 * 2;

const API_REQUEST_DEFAULT_HEADERS = {
  Accept: 'application/json',
};
const API_REQUEST_TIMEOUT = 5000;
const AXIOS_CONFIG = {
  timeout: API_REQUEST_TIMEOUT,
  headers: {
    ...API_REQUEST_DEFAULT_HEADERS,
  },
};

export type BuildingHttpClient = AxiosInstance & { blueApi?: boolean };

function createHttpClient({ baseURL, blueApi }: { baseURL: string, blueApi?: boolean }): BuildingHttpClient {
  const axiosInstance = setupCache(axios.create({ ...AXIOS_CONFIG, baseURL }), { ttl: CACHE_TTL });
  return Object.assign(axiosInstance, { blueApi } as any);
}

export default class Http {
  private abortController: AbortController = new AbortController();

  public readonly strapi = createHttpClient({ baseURL: GeneralConfig.strapiURL });

  public get signal() {
    return this.abortController.signal;
  }

  /**
   * abort all connections that use "signal" getter
   * @param reason status code or text why to aport
   */
  public abort(reason?: number | string) {
    this.abortController.abort(reason);
    this.abortController = new AbortController();
  }
}
