<template>
  <div v-if="!!requests" class="fixed z-50 top-0 left-0 w-full h-1 bg-primary">
    <div class="progress-bar absolute rounded-lg h-full bg-white" />
  </div>
</template>

<style lang="scss">
@keyframes loading {
        0% { width: 0; left: 0; }
        50% { width: 50%; }
        100% { left: 100% ; width: 100% }
    }
.progress-bar {
    animation: loading 1s infinite;
}
</style>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import { BuildingHttpClient } from '@/http';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

@Component({
  emits: ['loading'],
})
export default class ProgressBar extends ComponentBase {
  public requests = 0;

  private requestInceptors: { http: BuildingHttpClient, id: number }[] = [];

  private responseInceptors: { http: BuildingHttpClient, id: number }[] = [];

  public async created() {
    this.requestInceptors = [this.strapi].map((h) => ({ http: h, id: h.interceptors.request.use(this.addAxiosPendingRequest, this.subAxiosPendingRequestError) }));
    this.responseInceptors = [this.strapi].map((h) => ({ http: h, id: h.interceptors.response.use(this.subAxiosPendingRequest, this.subAxiosPendingRequestError) }));
  }

  public async beforeUnmount() {
    this.requestInceptors.map((i) => i.http.interceptors.request.eject(i.id));
    this.responseInceptors.map((i) => i.http.interceptors.response.eject(i.id));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private addAxiosPendingRequest(req: InternalAxiosRequestConfig) {
    this.requests += 1;
    if (this.requests > 0) {
      this.$emit('loading', true);
    }
    return req;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, class-methods-use-this
  private subAxiosPendingRequest(res: AxiosResponse) {
    this.requests = this.requests === 0 ? 0 : this.requests - 1;
    if (this.requests === 0) {
      this.$emit('loading', false);
    }
    return res;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private subAxiosPendingRequestError(err?: Error & { config?: InternalAxiosRequestConfig}) {
    this.requests = this.requests === 0 ? 0 : this.requests - 1;
    if (this.requests === 0) {
      this.$emit('loading', false);
    }
    return Promise.reject(err);
  }
}
</script>
