<template>
  <div class="flex pb-8 w-full justify-center items-center bg-surface-ground">
    <section class="container flex flex-wrap">
      <div class="w-full px-4 mt-10 md:w-1/2 lg:w-1/4" v-for="(item, index) of impact" :key="index">
        <div class="flex flex-col items-center group">
          <div class="relative z-10 mb-6 flex h-[40px] w-[40px] items-center justify-center">
            <span class="impact-icon-bg" />
            <fa-icon :icon="item.faIcon" size="lg" class="text-white" />
          </div>
          <p class="font-bold text-lg text-green-900"> {{ item.title }}</p>
          <p class="mb-3 text-base text-center font-bold">
            {{ item.subTitle }}
          </p>
          <p class="description text-center text-body-color text-sm" v-html="marked(item.description)" />
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
// @TODO: data from prop
@tailwind components;
@layer components {
  .impact-icon-bg {
    @apply absolute left-0 top-0 -z-[1] flex h-[40px] w-[40px] rotate-[25deg] items-center justify-center rounded-sm bg-green bg-opacity-20 duration-300 group-hover:rotate-45
  }
}
</style>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import GeneralConfig from '@/config/general';

@Component({
  props: {
    impact: {
      type: Object,
    },
  },
})
export default class HomeImpact extends ComponentBase {
  declare public impact: any;

  public GeneralConfig = GeneralConfig;
}
</script>
