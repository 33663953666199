import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeHero = _resolveComponent("HomeHero")!
  const _component_HomeImpact = _resolveComponent("HomeImpact")!
  const _component_HomeKeyFeatures = _resolveComponent("HomeKeyFeatures")!
  const _component_HomeIntegrations = _resolveComponent("HomeIntegrations")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HomeHero, {
      hero: _ctx.home.hero
    }, null, 8, ["hero"]),
    (_ctx.home.impact)
      ? (_openBlock(), _createBlock(_component_HomeImpact, {
          key: 0,
          impact: _ctx.home.impact
        }, null, 8, ["impact"]))
      : _createCommentVNode("", true),
    (_ctx.home.features)
      ? (_openBlock(), _createBlock(_component_HomeKeyFeatures, {
          key: 1,
          features: _ctx.home.features
        }, null, 8, ["features"]))
      : _createCommentVNode("", true),
    (_ctx.home.integrations)
      ? (_openBlock(), _createBlock(_component_HomeIntegrations, {
          key: 2,
          integrations: _ctx.home.integrations
        }, null, 8, ["integrations"]))
      : _createCommentVNode("", true)
  ]))
}