import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "relative inline-block text-left"
}
const _hoisted_2 = { class: "dropdown group" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = {
  type: "button",
  class: "inline-flex flex-nowrap bg-transparent justify-center items-center w-full px-4 py-2 text-sm font-medium"
}
const _hoisted_5 = { class: "whitespace-nowrap" }
const _hoisted_6 = { class: "dropdown-list z-50 right-0 absolute hidden group-hover:block bg-white border border-gray-300 w-50 rounded-md shadow-lg" }
const _hoisted_7 = { class: "py-1" }
const _hoisted_8 = ["href", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_ctx.currentLocale && _ctx.locales)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("button", _hoisted_4, [
              _createVNode(_component_fa_icon, {
                icon: "fa-globe",
                class: "mr-2"
              }),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.currentLocale.name), 1),
              _createVNode(_component_fa_icon, {
                icon: "caret-down",
                class: "ml-2 transition-transform transform group-hover:rotate-180"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("ul", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: locale.code
                }, [
                  _createElementVNode("a", {
                    href: `/${locale.code}/home`,
                    onClick: _withModifiers(($event: any) => (_ctx.changeLocale(locale)), ["prevent"]),
                    class: "whitespace-nowrap block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  }, _toDisplayString(locale.name), 9, _hoisted_8)
                ]))
              }), 128))
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}