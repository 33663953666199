import { createStore } from 'vuex';
// import VuexPersistence from 'vuex-persist';

import AppState from './modules/app-state';

/*
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'larva.io-business-store',
  modules: ['AppState'], // save locally only AppState module
});
*/
export default createStore({
  modules: {
    AppState,
  },
  // plugins: [vuexLocal.plugin],
});
