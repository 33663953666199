<template>
  <div class="flex items-center w-screen justify-center h-screen">
    <div class="max-w-sm text-center">
      <h1 class="mt-4 text-xl font-semibold text-gray-800">Under Maintenance</h1>
      <p class="mt-2 text-gray-600">We're currently performing scheduled maintenance. Sorry for the inconvenience.
        Please check back later.
      </p>
      <a href="/" class="mt-8 inline-block text-white px-6 py-3 bg-primary-500 hover:bg-primary-500 rounded-lg text-lg">Reload</a>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';

@Component({})
export default class NotFound extends ComponentBase {
  // @see https://developers.google.com/search/docs/crawling-indexing/javascript/javascript-seo-basics#avoid-soft-404s
  // eslint-disable-next-line class-methods-use-this
  public mounted() {
    document.querySelector('#app')?.classList.remove('loading');
    const element = document.querySelector('meta[name="robots"]');
    if (element) {
      element.remove();
    }
    const metaRobots = document.createElement('meta');
    metaRobots.name = 'robots';
    metaRobots.content = 'noindex';
    document.head.appendChild(metaRobots);
  }

  // eslint-disable-next-line class-methods-use-this
  public unmounted() {
    const element = document.querySelector('meta[name="robots"]');
    if (element) {
      element.remove();
    }
  }
}
</script>
