<template>
  <div class="flex bg-surface-ground align-center justify-center">
    <div v-if="global" class="container border-t px-4 border-stone-300 text-xs text-stone-900 pb-6 pt-4">
      ©{{ new Date().getFullYear() }} {{ global.footerAllRightsReserved }}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';

@Component({
  components: {},
})
export default class FooterContact extends ComponentBase {
  public get global() {
    return this.AppState.global;
  }
}

</script>
