<!-- eslint-disable vue/max-len -->
<template>
  <div>
    <HomeHero :hero="home.hero" />
    <HomeImpact v-if="home.impact" :impact="home.impact" />
    <HomeKeyFeatures v-if="home.features" :features="home.features" />
    <HomeIntegrations v-if="home.integrations" :integrations="home.integrations" />
  </div>
</template>

<style lang="scss" scoped>
@tailwind components;

@layer components {
  .loading {
    @apply relative flex justify-center items-center overflow-hidden bg-gradient-to-r from-40% from-primary to-primary-300 pt-[120px]
  }
}
</style>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import { strapiImageUrl } from '@/utils';
import HomeHero from './components/home-hero.vue';
import HomeImpact from './components/home-impact.vue';
import HomeIntegrations from './components/home-integrations.vue';
import HomeKeyFeatures from './components/home-key-features.vue';

@Component({
  components: {
    HomeHero,
    HomeImpact,
    HomeKeyFeatures,
    HomeIntegrations,
  },
  watch: {
    currentLocale() { // fetch data on each id change
      this.changed();
    },
  },
})
export default class Home extends ComponentBase {
  public loading = true;

  public home: any = {};

  public async created() {
    await this.changed();
  }

  public async changed() {
    try {
      this.loading = true;
      const { data: { data: { attributes: home } } } = await this.strapi.get('/homepage', {
        params: {
          locale: this.AppState.currentLocale?.code,
          populate: [
            'seo',
            'hero',
            'hero.image',
            'hero.smallImage',
            'impact',
            'features',
            'features.blocks',
            'integrations',
            'integrations.logosBusinessSoftware',
            'integrations.logosBuilding',
            'integrations.businessIntegrations',
            'integrations.buildingIntegrations',
          ],
        },
      });
      this.home = home;
      this.setMetaInfo(home.seo?.metaTitle, home.seo?.metaDescription);
      this.preloadImage(strapiImageUrl(home.hero?.image?.data?.attributes?.formats?.large?.url));
    } catch (err) {
    // @TODO: route to unexpected error
    } finally {
      document.querySelector('#app')?.classList.remove('loading');
      this.loading = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private preloadImage(url: string) {
    if (!url) {
      return;
    }
    if (document.querySelector(`head link[href="${url}"]`)) {
      return;
    }
    const head = document.querySelector('head');
    const link = document.createElement('link');
    link.fetchPriority = 'high';
    link.rel = 'preload';
    link.href = url;
    link.as = 'image';
    head?.appendChild(link);
  }

  public get currentLocale() {
    return this.AppState.currentLocale;
  }
}
</script>
