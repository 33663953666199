<!-- eslint-disable vue/max-len -->
<template>
  <div>
    <Transition
      enter-from-class="opacity-0"
      enter-active-class="transition duration-700"
    >
      <div v-if="description && !loading" class="flex w-full justify-center items-center bg-surface-ground">
        <section class="container flex flex-wrap py-10">
          <div class="w-full px-20"><p class="text-center" v-html="marked(description ?? '')" /></div>
        </section>
      </div>
    </Transition>
    <div class="flex align-center justify-center">
      <Transition
        enter-from-class="opacity-0"
        enter-active-class="transition duration-700"
      >
        <div v-if="!loading" class="container markdown my-10">
          <div v-for="feature of productFeatures" :key="feature.id" class="flex flex-wrap justify-center md:flex-nowrap pb-36" :class="{ 'flex-row-reverse': feature.imagePosition === 'right' }">
            <div class="w-full md:w-auto flex flex-col align-center justify-center text-center px-4 md:px-0">
              <h3 class="mb-3 text-3xl font-bold flex items-center justify-center">
                <span v-if="feature.badge" class="inline-block mr-2 bg-amber-500 text-white text-xs font-medium px-2.5 py-0.5 rounded">{{feature.badge}}</span>
                <span>{{ feature.title ?? feature.Title }}</span>
              </h3>
              <p v-html="marked(feature.content ?? '')" />
            </div>
            <div v-if="feature.image?.data?.attributes?.formats" class="md:max-w-[60%] md:min-w-[60%] flex flex-col justify-center items-center" :class="{ 'md:pr-10': feature.imagePosition === 'right', 'md:pl-10': feature.imagePosition !== 'right' }">
              <div class="max-w-[600px]">
                <div class="px-4 pt-4 md:pt-0 px-12 md:px-0 text-center">
                  <img :src="strapiImageUrl(feature.image?.data?.attributes?.formats?.large?.url ?? feature.image?.data?.attributes?.formats?.medium?.url ?? feature.image?.data?.attributes?.formats?.small?.url ?? feature.image?.data?.attributes?.formats?.thumbnail?.url)" :alt="feature.title ?? feature.Title" class="max-w-full inline-block max-h-96 border rounded-xl shadow-xl">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="h-screen" />
      </Transition>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import { strapiImageUrl } from '@/utils';

@Component({
  props: {
    id: {
      required: true,
    },
  },
  components: {},
  watch: {
    id() { // fetch data on each id change
      this.changed();
    },
  },
})
export default class Page extends ComponentBase {
  declare public id: string;

  public loading = false;

  public description = '';

  public productFeatures: any[] = [];

  public strapiImageUrl = strapiImageUrl;

  public created() {
    this.changed();
  }

  // eslint-disable-next-line class-methods-use-this
  public async changed() {
    try {
      this.loading = true;
      const { data: { data: { attributes } } } = await this.strapi.get<any>(`/features/${this.id}`, {
        params: {
          locale: this.AppState.currentLocale?.code,
          populate: [
            'seo',
            'productFeatures',
            'productFeatures.image',
          ],
        },
      });
      this.description = attributes.description;
      this.productFeatures = attributes.productFeatures ?? [];
      this.setMetaInfo(attributes.seo?.metaTitle, attributes.seo?.metaDescription);
    } catch (err) {
      // @TODO: internal server error
      console.error(err);
    } finally {
      document.querySelector('#app')?.classList.remove('loading');
      this.loading = false;
    }
  }
}
</script>
