<!-- eslint-disable vue/max-len -->
<template>
  <div class="flex pt-14 pb-14 w-full justify-center items-center bg-transparent">
    <section class="container flex flex-wrap">
      <div class="grid grid-cols-4">
        <div class="col lg:col-span-1 col-span-4 p-4">
          <span class="uppercase block text-base font-medium mb-6">{{ features.subTitle }}</span>
          <h2 class="mb-3 text-3xl font-bold">
            {{ features.title }}
          </h2>
          <p class="description pt-4" v-html="marked(features.description)" />
        </div>
        <div class="col lg:col-span-3 col-span-4">
          <div class="grid grid-cols-2" v-if="features.blocks">
            <div class="col col-span-2 pb-12 pt-4" :class="{ 'md:col-span-1': features.blocks.length % 2 === 0 || index !== features.blocks.length - 1, 'md:col-span-2': features.blocks.length % 2 !== 0 && index === features.blocks.length - 1 }" v-for="(feature, index) of features.blocks" :key="feature.id">
              <div class="bg-surface-ground mx-2 h-full p-2 rounded-xl">
                <div class="-mt-8 flex flex-col items-center group mb-2">
                  <div class="relative z-10 mb-2 flex h-[55px] w-[55px] items-center justify-center">
                    <span class="absolute left-0 top-0 -z-[1] mb-0 flex h-[55px] w-[55px] items-center justify-center rounded-full bg-primary" />
                    <fa-icon :icon="feature.faIcon" size="xl" class="text-white" />
                  </div>
                </div>
                <h3 class="mb-3 text-xl font-bold text-center flex items-center justify-center">
                  {{ feature.title }}
                  <span v-if="feature.badge" class="bg-amber-500 ml-2 text-xs font-medium px-2.5 py-0.5 rounded">{{feature.badge}}</span>
                </h3>
                <div class="description px-4 py-2" v-html="marked(feature.description)" />
                <div class="flex justify-end">
                  <a :href="`/${currentLocale?.code}${feature.linkHref}`" @click.prevent="$router.push(`/${currentLocale?.code}${feature.linkHref}`)" class="feature-more">
                    {{ feature.linkTitle }}
                    <fa-icon class="ml-1" icon="chevron-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
// @TODO: data from prop
@tailwind components;
@layer components {
  .feature-more {
    @apply inline-flex items-center justify-end text-white px-3 py-1 text-right text-base font-medium text-primary hover:text-primary-400

  }
}
</style>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import GeneralConfig from '@/config/general';

@Component({
  props: {
    features: {
      type: Object,
    },
  },
})
export default class HomeKeyFeatures extends ComponentBase {
  declare public features: any;

  public GeneralConfig = GeneralConfig;

  public get currentLocale() {
    return this.AppState.currentLocale;
  }
}
</script>
