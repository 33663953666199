<template>
  <div class="flex align-center justify-center">
    <div class="container my-10">
      <div class="px-4 sm:px-0 flex flex-col items-center">
        <h1 class="text-9xl font-bold">404</h1>
        <p class="text-4xl mt-8">Oops! Page not found</p>
        <p class="text-xl mt-4">The page you are looking for might have been removed or doesn't exist.</p>
        <a href="/" @click.prevent="home" class="mt-8 inline-block text-white px-6 py-3 bg-primary-500 hover:bg-primary-500 rounded-lg text-lg">Home</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';

@Component({})
export default class NotFound extends ComponentBase {
  public home() {
    this.$router.push({ name: 'home.locale', params: { locale: this.AppState.currentLocale?.code ?? 'en' } });
  }

  // @see https://developers.google.com/search/docs/crawling-indexing/javascript/javascript-seo-basics#avoid-soft-404s
  // eslint-disable-next-line class-methods-use-this
  public mounted() {
    document.querySelector('#app')?.classList.remove('loading');
    const element = document.querySelector('meta[name="robots"]');
    if (element) {
      element.remove();
    }
    const metaRobots = document.createElement('meta');
    metaRobots.name = 'robots';
    metaRobots.content = 'noindex';
    document.head.appendChild(metaRobots);
  }

  // eslint-disable-next-line class-methods-use-this
  public unmounted() {
    const element = document.querySelector('meta[name="robots"]');
    if (element) {
      element.remove();
    }
  }
}
</script>
