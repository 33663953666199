<template>
  <div v-if="currentLocale && locales" class="relative inline-block text-left">
    <div class="dropdown group">
      <span class="">
        <button
          type="button"
          class="inline-flex flex-nowrap bg-transparent justify-center items-center w-full px-4 py-2 text-sm font-medium">
          <fa-icon icon="fa-globe" class="mr-2" />
          <span class="whitespace-nowrap">{{ currentLocale.name}}</span>
          <fa-icon icon="caret-down" class="ml-2 transition-transform transform group-hover:rotate-180" />
        </button>
      </span>
      <div
        class="dropdown-list z-50 right-0 absolute hidden group-hover:block bg-white border border-gray-300 w-50 rounded-md shadow-lg">
        <ul class="py-1">
          <li v-for="locale of locales" :key="locale.code"><a
            :href="`/${locale.code}/home`"
            @click.prevent="changeLocale(locale)"
            class="whitespace-nowrap block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">{{ locale.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';

@Component({
  components: {},
})
export default class Locale extends ComponentBase {
  public get currentLocale() {
    return this.AppState.currentLocale;
  }

  public get locales() {
    return this.AppState.locales;
  }

  public changeLocale(locale: any) {
    this.AppState.setCurrentLocale(locale);
    this.initCurrentLocale();
    this.$router.push({ name: 'home.locale', params: { locale: locale.code } });
  }
}
</script>
