<!-- eslint-disable vue/max-len -->
<template>
  <ProgressBar @loading="(v: boolean) => loading = v" />
  <div :class="{ 'blur-sm': loading }" class="lar-blur">
    <Nav />
    <router-view />
    <FooterContact />
    <FooterNav />
    <Footer />
  </div>
</template>

<style>
.lar-blur {
  transition: .2s filter ease-out;
}
</style>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import Nav from './components/nav.vue';
import FooterContact from './components/footer-contact.vue';
import Footer from './components/footer.vue';
import FooterNav from './components/footer-nav.vue';
import ProgressBar from './components/progress-bar.vue';

@Component({
  components: {
    Nav,
    FooterContact,
    Footer,
    FooterNav,
    ProgressBar,
  },
})
export default class Main extends ComponentBase {
  public loading = false;
}
</script>
