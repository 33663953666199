import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8c8313a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero shadow-lg" }
const _hoisted_2 = {
  key: 0,
  class: "container"
}
const _hoisted_3 = { class: "mx-auto max-w-[820px] text-center" }
const _hoisted_4 = { class: "uppercase text-lg text-white mb-2" }
const _hoisted_5 = { class: "hero-title" }
const _hoisted_6 = { class: "hero-description" }
const _hoisted_7 = { class: "mb-10 flex flex-wrap items-center justify-center gap-5" }
const _hoisted_8 = {
  key: 0,
  class: "w-full px-4"
}
const _hoisted_9 = { class: "relative z-10 mx-auto max-w-[845px]" }
const _hoisted_10 = { class: "mt-16 shadow-xl" }
const _hoisted_11 = ["srcset", "src", "width", "height"]
const _hoisted_12 = {
  key: 0,
  class: "absolute border rounded-t-xl rounded-tr-xl overflow-hidden shadow-xl max-w-[240px] md:max-w-[360px] -right-24 top-20 z-10"
}
const _hoisted_13 = ["srcset", "src", "width", "height"]
const _hoisted_14 = { class: "absolute -left-9 bottom-0 z-[-1]" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "absolute -right-6 -top-6 z-[-1]" }
const _hoisted_17 = ["src"]
const _hoisted_18 = {
  key: 1,
  style: {"min-height":"400vh"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_Transition, {
      "enter-from-class": "opacity-0",
      "enter-active-class": "transition duration-800"
    }, {
      default: _withCtx(() => [
        (_ctx.hero)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.hero.subTitle), 1),
                _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.hero.title), 1),
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.hero.description), 1),
                _createElementVNode("ul", _hoisted_7, [
                  _createElementVNode("li", null, [
                    _createElementVNode("a", {
                      href: "#footer-contact-a",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.scrollToContact && _ctx.scrollToContact(...args)), ["prevent"])),
                      class: "hero-btn"
                    }, _toDisplayString(_ctx.hero.ctaLabel), 1)
                  ])
                ])
              ]),
              (_ctx.hero.image?.data?.attributes?.formats?.large?.url)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("img", {
                          srcset: `${_ctx.strapiImageUrl(_ctx.hero.image?.data?.attributes?.formats?.small?.url)} 500w, ${_ctx.strapiImageUrl(_ctx.hero.image?.data?.attributes?.formats?.medium?.url)} 600w, ${_ctx.strapiImageUrl(_ctx.hero.image?.data?.attributes?.formats?.large?.url)} 800w`,
                          sizes: "(max-width: 500px) 500px, (max-width: 600px) 480px, 800px",
                          src: _ctx.strapiImageUrl(_ctx.hero.image?.data?.attributes?.formats?.large?.url),
                          alt: "hero",
                          width: _ctx.hero.image?.data?.attributes?.formats?.large.width,
                          height: _ctx.hero.image?.data?.attributes?.formats?.large.height,
                          class: "mx-auto max-w-full rounded-t-xl rounded-tr-xl"
                        }, null, 8, _hoisted_11)
                      ]),
                      (_ctx.hero.smallImage?.data?.attributes?.formats?.small?.url)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createElementVNode("img", {
                              srcset: `${_ctx.strapiImageUrl(_ctx.hero.smallImage?.data?.attributes?.formats?.thumbnail?.url)} 500w, ${_ctx.strapiImageUrl(_ctx.hero.smallImage?.data?.attributes?.formats?.large?.url)} 800w`,
                              sizes: "(max-width: 500px) 500px, 800px",
                              src: _ctx.strapiImageUrl(_ctx.hero.smallImage?.data?.attributes?.formats?.small?.url),
                              alt: "hero-small",
                              width: _ctx.hero.smallImage?.data?.attributes?.formats?.large.width,
                              height: _ctx.hero.smallImage?.data?.attributes?.formats?.large.height,
                              class: "mx-auto max-w-full"
                            }, null, 8, _hoisted_13)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("img", {
                          src: require('@/assets/dots.svg'),
                          alt: "dots"
                        }, null, 8, _hoisted_15)
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("img", {
                          src: require('@/assets/dots.svg'),
                          alt: "dots"
                        }, null, 8, _hoisted_17)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_18))
      ]),
      _: 1
    })
  ]))
}