import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Nav = _resolveComponent("Nav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterContact = _resolveComponent("FooterContact")!
  const _component_FooterNav = _resolveComponent("FooterNav")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ProgressBar, {
      onLoading: _cache[0] || (_cache[0] = (v) => _ctx.loading = v)
    }),
    _createElementVNode("div", {
      class: _normalizeClass([{ 'blur-sm': _ctx.loading }, "lar-blur"])
    }, [
      _createVNode(_component_Nav),
      _createVNode(_component_router_view),
      _createVNode(_component_FooterContact),
      _createVNode(_component_FooterNav),
      _createVNode(_component_Footer)
    ], 2)
  ], 64))
}