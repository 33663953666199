import { createApp } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faBrain } from '@fortawesome/free-solid-svg-icons/faBrain';
import { faBuildingShield } from '@fortawesome/free-solid-svg-icons/faBuildingShield';
import { faBuildingUser } from '@fortawesome/free-solid-svg-icons/faBuildingUser';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faFaceSmile } from '@fortawesome/free-solid-svg-icons/faFaceSmile';
import { faLeaf } from '@fortawesome/free-solid-svg-icons/faLeaf';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons/faLocationDot';
import { faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons/faMoneyBillTrendUp';
import { faPersonCircleCheck } from '@fortawesome/free-solid-svg-icons/faPersonCircleCheck';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons/faUserSecret';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud';
import { faMicrochip } from '@fortawesome/free-solid-svg-icons/faMicrochip';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { faChargingStation } from '@fortawesome/free-solid-svg-icons/faChargingStation';
import { faCircleNodes } from '@fortawesome/free-solid-svg-icons/faCircleNodes';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons/faDoorOpen';
import { faDroplet } from '@fortawesome/free-solid-svg-icons/faDroplet';
import { faEthernet } from '@fortawesome/free-solid-svg-icons/faEthernet';
import { faFan } from '@fortawesome/free-solid-svg-icons/faFan';
import { faFaucet } from '@fortawesome/free-solid-svg-icons/faFaucet';
import { faFire } from '@fortawesome/free-solid-svg-icons/faFire';
import { faFireExtinguisher } from '@fortawesome/free-solid-svg-icons/faFireExtinguisher';
import { faIcons } from '@fortawesome/free-solid-svg-icons/faIcons';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons/faLightbulb';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons/faNetworkWired';
import { faPlug } from '@fortawesome/free-solid-svg-icons/faPlug';
import { faServer } from '@fortawesome/free-solid-svg-icons/faServer';
import { faShield } from '@fortawesome/free-solid-svg-icons/faShield';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons/faSnowflake';
import { faSolarPanel } from '@fortawesome/free-solid-svg-icons/faSolarPanel';
import { faSpa } from '@fortawesome/free-solid-svg-icons/faSpa';
import { faTemperatureThreeQuarters } from '@fortawesome/free-solid-svg-icons/faTemperatureThreeQuarters';
import { faTowerBroadcast } from '@fortawesome/free-solid-svg-icons/faTowerBroadcast';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faWater } from '@fortawesome/free-solid-svg-icons/faWater';
import { faWifi } from '@fortawesome/free-solid-svg-icons/faWifi';
import { faWind } from '@fortawesome/free-solid-svg-icons/faWind';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import Http from './http';

// main app
import App from './App.vue';
import Router from './router';
import Store from './store';

// @TODO: import all needed icons
library.add(
  faUserSecret,
  faChevronRight,
  faClipboardCheck,
  faChartLine,
  faPersonCircleCheck,
  faLeaf,
  faFaceSmile,
  faMoneyBillTrendUp,
  faBuildingShield,
  faBuildingUser,
  faBrain,
  faChevronDown,
  faLocationDot,
  faEnvelope,
  faPhone,
  faLinkedin,
  faCloud,
  faMicrochip,
  faFireExtinguisher,
  faFire,
  faWind,
  faTowerBroadcast,
  faFan,
  faNetworkWired,
  faEthernet,
  faWifi,
  faServer,
  faTemperatureThreeQuarters,
  faDroplet,
  faSnowflake,
  faCamera,
  faVideo,
  faIcons,
  faPlug,
  faLightbulb,
  faChargingStation,
  faSolarPanel,
  faWater,
  faLock,
  faShield,
  faCircleNodes,
  faFaucet,
  faSpa,
  faDoorOpen,
  faCircleNotch,
  faGlobe,
  faCaretDown,
);

const app = createApp(App)
  .use(Store)
  .use(Router)
  .component('fa-icon', FontAwesomeIcon)
  .provide('http', new Http());

Router.isReady()
  .then(() => app.mount('#app'))
  .catch((err: Error) => {
    console.error('Error', err);
    // eslint-disable-next-line no-alert
    alert(err.message);
  });
