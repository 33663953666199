<!-- eslint-disable vue/max-len -->
<template>
  <div>
    <div class="flex w-full justify-center items-center bg-surface-ground">
      <Transition
        enter-from-class="opacity-0"
        enter-active-class="transition duration-700"
      >
        <section v-if="description && !loading" class="container flex flex-wrap pt-10">
          <div class="w-full px-20"><p class="text-center" v-html="marked(description ?? '')" /></div>
        </section>
      </Transition>
    </div>
    <div class="flex align-center justify-center bg-surface-ground">
      <Transition
        enter-from-class="opacity-0"
        enter-active-class="transition duration-700"
      >
        <div v-if="!loading" class="container markdown mt-10 flex flex-wrap justify-start">
          <div class="w-full px-4 sm:w-full lg:w-1/2 xl:w-1/2 py-5" v-for="section of sections" :key="section.id">
            <div class="group flex flex-col justify-center rounded-xl md:h-full bg-white px-5 py-5">
              <h2 class="mb-1 text-xl font-semibold text-dark">{{ section.title }}</h2>
              <div class="markdown" v-html="marked(section.description ?? '')" />
            </div>
          </div>
        </div>
        <div v-else class="h-screen" />
      </Transition>
    </div>
    <div class="flex align-center justify-center bg-surface-ground">
      <Transition
        enter-from-class="opacity-0"
        enter-active-class="transition duration-700"
      >
        <div v-if="!loading" class="container markdown my-10">
          <div class="flex flex-wrap justify-center">
            <div class="w-full pb-8 px-4 sm:w-1/2 lg:w-1/4 xl:w-1/4" v-for="member of team" :key="member.id">
              <div class="group sm:h-full rounded-xl bg-white px-5 pb-10 pt-12">
                <div class="relative z-10 mx-auto mb-5 h-[120px] w-[120px]">
                  <img :src="strapiImageUrl(member.image?.data?.attributes?.formats?.small?.url ?? member.image?.data?.attributes?.formats?.medium?.url ?? member.image?.data?.attributes?.formats?.large?.url)" :alt="member.name" class="h-[120px] w-[120px] object-cover rounded-full">
                  <span class="absolute right-0 top-0 -z-10 opacity-0 transition-all group-hover:opacity-100">
                    <img :src="require('@/assets/arrow.svg')" alt="arrow" />
                  </span>
                </div>
                <div class="text-center">
                  <h4 class="mb-1 text-lg font-semibold text-dark">
                    {{ member.name }}
                  </h4>
                  <p class="mb-5 text-sm text-body-color">
                    {{ member.position }}
                  </p>
                  <div class="mt-2">
                    <div v-if="member.linkedin" class="mb-1"><a :href="`https://eu.linkedin.com/in/${member.linkedin}`" target="_blank" rel="noopener"><fa-icon icon="fa-brands fa-linkedin" class="mr-1" />Linkedin</a>&nbsp;</div>
                    <div v-if="member.email" class="mb-1"><a :href="`mailto:${member.email}`"><fa-icon icon="envelope" class="mr-1" />{{ member.email }}</a>&nbsp;</div>
                    <div v-if="member.phone" class="mb-1"><a :href="`tel:${member.phone.replace(/ /g, '')}`"><fa-icon icon="phone" class="mr-1" />{{ member.phone }}</a>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="h-screen" />
      </Transition>
    </div>
    <div class="flex my-10 align-center justify-center gap-4">
      <div class="max-w-[250px] markdown text-right" v-html="marked(footerText)" />
      <div class="max-w-[200px]">
        <img :src="strapiImageUrl(footerImage?.data?.attributes?.formats?.small?.url ?? footerImage?.data?.attributes?.formats?.medium?.url ?? footerImage?.data?.attributes?.formats?.large?.url)" alt="Fentrica office" class="object-cover mx-auto max-w-full rounded-full">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import { strapiImageUrl } from '@/utils';

  @Component({
    props: {
      id: {
        required: true,
      },
    },
    components: {},
    watch: {
      id() { // fetch data on each id change
        this.changed();
      },
    },
  })
export default class Company extends ComponentBase {
  declare public id: string;

  public loading = false;

  public description = '';

  public footerText = '';

  public footerImage: any = null;

  public team: any[] = [];

  public sections: any[] = [];

  public strapiImageUrl = strapiImageUrl;

  public created() {
    this.changed();
  }

  // eslint-disable-next-line class-methods-use-this
  public async changed() {
    try {
      this.loading = true;
      const { data: { data: { attributes } } } = await this.strapi.get<any>('/company', {
        params: {
          locale: this.AppState.currentLocale?.code,
          populate: [
            'seo',
            'team',
            'team.image',
            'sections',
            'footerImage',
          ],
        },
      });
      this.description = attributes.description;
      this.team = attributes.team ?? [];
      this.sections = attributes.sections ?? [];
      this.footerText = attributes.footerText;
      this.footerImage = attributes.footerImage;

      this.setMetaInfo(attributes.seo?.metaTitle, attributes.seo?.metaDescription);
    } catch (err) {
      // @TODO: internal server error
      console.error(err);
    } finally {
      document.querySelector('#app')?.classList.remove('loading');
      this.loading = false;
    }
  }
}
</script>
