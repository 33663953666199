import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex w-full justify-center items-center bg-surface-ground" }
const _hoisted_2 = {
  key: 0,
  class: "container flex flex-wrap pt-10"
}
const _hoisted_3 = { class: "w-full px-20" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "flex align-center justify-center bg-surface-ground" }
const _hoisted_6 = {
  key: 0,
  class: "container markdown mt-10 flex flex-wrap justify-start"
}
const _hoisted_7 = { class: "group flex flex-col justify-center rounded-xl md:h-full bg-white px-5 py-5" }
const _hoisted_8 = { class: "mb-1 text-xl font-semibold text-dark" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 1,
  class: "h-screen"
}
const _hoisted_11 = { class: "flex align-center justify-center bg-surface-ground" }
const _hoisted_12 = {
  key: 0,
  class: "container markdown my-10"
}
const _hoisted_13 = { class: "flex flex-wrap justify-center" }
const _hoisted_14 = { class: "group sm:h-full rounded-xl bg-white px-5 pb-10 pt-12" }
const _hoisted_15 = { class: "relative z-10 mx-auto mb-5 h-[120px] w-[120px]" }
const _hoisted_16 = ["src", "alt"]
const _hoisted_17 = { class: "absolute right-0 top-0 -z-10 opacity-0 transition-all group-hover:opacity-100" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "text-center" }
const _hoisted_20 = { class: "mb-1 text-lg font-semibold text-dark" }
const _hoisted_21 = { class: "mb-5 text-sm text-body-color" }
const _hoisted_22 = { class: "mt-2" }
const _hoisted_23 = {
  key: 0,
  class: "mb-1"
}
const _hoisted_24 = ["href"]
const _hoisted_25 = {
  key: 1,
  class: "mb-1"
}
const _hoisted_26 = ["href"]
const _hoisted_27 = {
  key: 2,
  class: "mb-1"
}
const _hoisted_28 = ["href"]
const _hoisted_29 = {
  key: 1,
  class: "h-screen"
}
const _hoisted_30 = { class: "flex my-10 align-center justify-center gap-4" }
const _hoisted_31 = ["innerHTML"]
const _hoisted_32 = { class: "max-w-[200px]" }
const _hoisted_33 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_Transition, {
        "enter-from-class": "opacity-0",
        "enter-active-class": "transition duration-700"
      }, {
        default: _withCtx(() => [
          (_ctx.description && !_ctx.loading)
            ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", {
                    class: "text-center",
                    innerHTML: _ctx.marked(_ctx.description ?? '')
                  }, null, 8, _hoisted_4)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_Transition, {
        "enter-from-class": "opacity-0",
        "enter-active-class": "transition duration-700"
      }, {
        default: _withCtx(() => [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "w-full px-4 sm:w-full lg:w-1/2 xl:w-1/2 py-5",
                    key: section.id
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("h2", _hoisted_8, _toDisplayString(section.title), 1),
                      _createElementVNode("div", {
                        class: "markdown",
                        innerHTML: _ctx.marked(section.description ?? '')
                      }, null, 8, _hoisted_9)
                    ])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_10))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_Transition, {
        "enter-from-class": "opacity-0",
        "enter-active-class": "transition duration-700"
      }, {
        default: _withCtx(() => [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.team, (member) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "w-full pb-8 px-4 sm:w-1/2 lg:w-1/4 xl:w-1/4",
                      key: member.id
                    }, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("img", {
                            src: _ctx.strapiImageUrl(member.image?.data?.attributes?.formats?.small?.url ?? member.image?.data?.attributes?.formats?.medium?.url ?? member.image?.data?.attributes?.formats?.large?.url),
                            alt: member.name,
                            class: "h-[120px] w-[120px] object-cover rounded-full"
                          }, null, 8, _hoisted_16),
                          _createElementVNode("span", _hoisted_17, [
                            _createElementVNode("img", {
                              src: require('@/assets/arrow.svg'),
                              alt: "arrow"
                            }, null, 8, _hoisted_18)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("h4", _hoisted_20, _toDisplayString(member.name), 1),
                          _createElementVNode("p", _hoisted_21, _toDisplayString(member.position), 1),
                          _createElementVNode("div", _hoisted_22, [
                            (member.linkedin)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                  _createElementVNode("a", {
                                    href: `https://eu.linkedin.com/in/${member.linkedin}`,
                                    target: "_blank",
                                    rel: "noopener"
                                  }, [
                                    _createVNode(_component_fa_icon, {
                                      icon: "fa-brands fa-linkedin",
                                      class: "mr-1"
                                    }),
                                    _createTextVNode("Linkedin")
                                  ], 8, _hoisted_24),
                                  _createTextVNode(" ")
                                ]))
                              : _createCommentVNode("", true),
                            (member.email)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                  _createElementVNode("a", {
                                    href: `mailto:${member.email}`
                                  }, [
                                    _createVNode(_component_fa_icon, {
                                      icon: "envelope",
                                      class: "mr-1"
                                    }),
                                    _createTextVNode(_toDisplayString(member.email), 1)
                                  ], 8, _hoisted_26),
                                  _createTextVNode(" ")
                                ]))
                              : _createCommentVNode("", true),
                            (member.phone)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                  _createElementVNode("a", {
                                    href: `tel:${member.phone.replace(/ /g, '')}`
                                  }, [
                                    _createVNode(_component_fa_icon, {
                                      icon: "phone",
                                      class: "mr-1"
                                    }),
                                    _createTextVNode(_toDisplayString(member.phone), 1)
                                  ], 8, _hoisted_28),
                                  _createTextVNode(" ")
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ])
                    ]))
                  }), 128))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_29))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_30, [
      _createElementVNode("div", {
        class: "max-w-[250px] markdown text-right",
        innerHTML: _ctx.marked(_ctx.footerText)
      }, null, 8, _hoisted_31),
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("img", {
          src: _ctx.strapiImageUrl(_ctx.footerImage?.data?.attributes?.formats?.small?.url ?? _ctx.footerImage?.data?.attributes?.formats?.medium?.url ?? _ctx.footerImage?.data?.attributes?.formats?.large?.url),
          alt: "Fentrica office",
          class: "object-cover mx-auto max-w-full rounded-full"
        }, null, 8, _hoisted_33)
      ])
    ])
  ]))
}