import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Locale } from './interfaces';

@Module({
  name: 'AppState',
  namespaced: true,
})
export default class AppState extends VuexModule {
  private globalData: any | null = null;

  private navigationData: any | null = null;

  private footNavigationData: any | null = null;

  private localesData: Locale[]| null = null;

  private currentLocaleData: Locale| null = null;

  // getters
  get global() { return this.globalData; }

  get nav() { return this.navigationData; }

  get footNav() { return this.footNavigationData; }

  get locales() { return this.localesData; }

  get currentLocale() { return this.currentLocaleData; }

  @Mutation setGlobal(value: any) {
    this.globalData = value;
  }

  @Mutation setNav(value: any) {
    this.navigationData = value;
  }

  @Mutation setFootNav(value: any) {
    this.footNavigationData = value;
  }

  @Mutation setLocales(value: Locale[]) {
    this.localesData = value;
  }

  @Mutation setCurrentLocale(value: Locale) {
    this.currentLocaleData = value;
  }

  @Mutation clear() {
    this.navigationData = null;
    this.footNavigationData = null;
    this.currentLocaleData = null;
    this.globalData = null;
  }
}
