<template>
  <div class="flex align-center justify-center">
    <div class="container markdown my-10">
      <Transition
        enter-from-class="opacity-0"
        enter-active-class="transition duration-700"
      >
        <div class="px-4 sm:px-0" v-if="!loading" v-html="marked(content)" />
        <div v-else class="h-screen" />
      </Transition>
      <!--  -->
    </div>
  </div>
</template>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';

@Component({
  props: {
    id: {
      required: true,
    },
  },
  components: {},
  watch: {
    id() { // fetch data on each id change
      this.changed();
    },
  },
})
export default class Page extends ComponentBase {
  declare public id: string;

  public loading = false;

  public content = '';

  public created() {
    this.changed();
  }

  // eslint-disable-next-line class-methods-use-this
  public async changed() {
    try {
      this.loading = true;
      const { data: { data: { attributes } } } = await this.strapi.get<any>(`/pages/${this.id}`, {
        params: {
          locale: this.AppState.currentLocale?.code,
          populate: [
            'seo',
          ],
        },
      });
      this.content = attributes.content;
      this.setMetaInfo(attributes.seo?.metaTitle, attributes.seo?.metaDescription);
    } catch (err) {
      // @TODO: internal server error
      console.error(err);
    } finally {
      document.querySelector('#app')?.classList.remove('loading');
      this.loading = false;
    }
  }
}
</script>
