<!-- eslint-disable vue/max-len -->
<template>
  <div class="flex items-center justify-center bg-surface-ground pb-16 pt-8 w-full">
    <div class="container z-10">
      <div class="flex flex-wrap mt-4">
        <div class="info w-full lg:w-1/2 text-right flex flex-col justify-center">
          <h2 class="mb-4 text-3xl text-center font-bold sm:text-4xl">
            {{ integrations.title }}
          </h2>
          <div><p class="text-base text-center text-body-color" v-html="marked(integrations.description)" /></div>
          <div class="mt-10 md:mt-24 group flex flex-wrap justify-center align-center content-center" v-if="integrations.logosBusinessSoftware?.data">
            <img
              class="transition-all duration-1000 max-w-22 max-h-5 grayscale group-hover:grayscale-0 h-auto w-auto inline-block my-2 mx-3"
              :src="strapiImageUrl(logo.attributes?.formats?.thumbnail?.url ?? logo.attributes?.formats?.small?.url ?? logo.attributes?.formats?.medium?.url ?? logo.attributes?.formats?.large?.url ?? logo.attributes?.url)"
              alt="Business Partner"
              :width="logo.attributes?.formats?.thumbnail?.width ?? logo.attributes?.formats?.small?.width ?? logo.attributes?.formats?.medium?.width ?? logo.attributes?.formats?.large?.width ?? logo.attributes?.width"
              :height="logo.attributes?.formats?.thumbnail?.height ?? logo.attributes?.formats?.small?.height ?? logo.attributes?.formats?.medium?.height ?? logo.attributes?.formats?.large?.height ?? logo.attributes?.height"
              v-for="logo of integrations.logosBusinessSoftware?.data"
              :key="logo.id"
            />
            <img
              class="transition-all duration-1000 max-w-22 max-h-5 grayscale group-hover:grayscale-0 h-auto w-auto inline-block my-2 mx-3"
              :src="strapiImageUrl(logo.attributes?.formats?.thumbnail?.url ?? logo.attributes?.formats?.small?.url ?? logo.attributes?.formats?.medium?.url ?? logo.attributes?.formats?.large?.url ?? logo.attributes?.url)"
              alt="Business Partner"
              :width="logo.attributes?.formats?.thumbnail?.width ?? logo.attributes?.formats?.small?.width ?? logo.attributes?.formats?.medium?.width ?? logo.attributes?.formats?.large?.width ?? logo.attributes?.width"
              :height="logo.attributes?.formats?.thumbnail?.height ?? logo.attributes?.formats?.small?.height ?? logo.attributes?.formats?.medium?.height ?? logo.attributes?.formats?.large?.height ?? logo.attributes?.height"
              v-for="logo of integrations.logosBuilding?.data"
              :key="logo.id"
            />
          </div>
        </div>
        <div class="w-full overflow-hidden mt-10 lg:mt-0 lg:w-1/2 icons-building flex flex-col items-center justify-center">
          <div class="top-integrations">
            <div v-for="integration of integrations.businessIntegrations" :key="integration.id" class="bg-primary text-white rounded py-1 px-4 my-1 text-center">{{ integration.title }}</div>
          </div>
          <div class="dots" />
          <fa-icon size="5x" icon="cloud" class="text-primary" />
          <div class="text-xs uppercase">{{ integrations.cloudTitle }}</div>
          <div class="dots" />
          <div class="icon device-icon"><fa-icon icon="microchip" /></div>
          <div class="text-xs uppercase">{{ integrations.deviceTitle }}</div>
          <div class="dots" />
          <div class="wrapper border-t border-dashed border-t-2 flex border-primary justify-center">
            <div class="icon-container" v-for="integration of integrations.buildingIntegrations" :key="integration.id"><div class="dots" /><div class="icon"><fa-icon :icon="integration.faIcon" /></div></div>
          </div>
          <div class="text-xs uppercase mt-4">{{ integrations.buildingTechsysTitle }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @tailwind components;

  .icon  {
    font-size: 15px;
  }
  .icon.device-icon {
    font-size: 20px;
  }
  @layer components {
    .icon-container {
      @apply flex flex-col items-center justify-center;
    }
    .icon {
      @apply mb-0 flex h-[25px] w-[25px] items-center justify-center rounded-full bg-primary text-white mx-2;
    }
    .icon.device-icon {
      @apply h-[35px] w-[35px];
    }
    .dots {
      @apply my-1 border-r border-dashed border-primary h-[20px] border-r-2
    }
  }
</style>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import GeneralConfig from '@/config/general';
import { strapiImageUrl } from '@/utils';

@Component({
  props: {
    integrations: {
      type: Object,
    },
  },
})
export default class HomeIntegrations extends ComponentBase {
  declare public integrations: any;

  public GeneralConfig = GeneralConfig;

  public strapiImageUrl = strapiImageUrl;
}
</script>
