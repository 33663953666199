import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import MainLayout from '@/layouts/main.vue';
import Home from '@/pages/home.vue';
import Resource from '@/pages/resource.vue';
import Maintenance from '@/pages/maintenance.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
  },
  {
    path: '/',
    name: 'home',
    component: MainLayout,
    // redirect handled by App.vue
    children: [
      // old links redirect
      { path: 'et/terms', redirect: '/et/informatsioon/kasutustingimused' },
      { path: 'et/policy', redirect: '/et/informatsioon/privaatsusreeglid' },
      //
      { path: ':locale/home', name: 'home.locale', component: Home },
      {
        path: ':locale/:catchAll(.*)',
        component: Resource,
      },
      {
        path: ':catchAll(.*)',
        component: Resource,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

export default router;
