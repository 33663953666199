<template>
  <section class="hero shadow-lg">
    <Transition enter-from-class="opacity-0" enter-active-class="transition duration-800">
      <div class="container" v-if="hero">
        <div class="mx-auto max-w-[820px] text-center">
          <p class="uppercase text-lg text-white mb-2">{{ hero.subTitle }}</p>
          <h1 class="hero-title">
            {{ hero.title }}
          </h1>
          <p class="hero-description">
            {{ hero.description }}
          </p>
          <ul class="mb-10 flex flex-wrap items-center justify-center gap-5">
            <li>
              <a href="#footer-contact-a" @click.prevent="scrollToContact" class="hero-btn">
                {{ hero.ctaLabel }}
              </a>
            </li>
          </ul>
        </div>
        <div class="w-full px-4" v-if="hero.image?.data?.attributes?.formats?.large?.url">
          <div class="relative z-10 mx-auto max-w-[845px]">
            <div class="mt-16 shadow-xl">
              <img
                :srcset="`${strapiImageUrl(hero.image?.data?.attributes?.formats?.small?.url)} 500w, ${strapiImageUrl(hero.image?.data?.attributes?.formats?.medium?.url)} 600w, ${strapiImageUrl(hero.image?.data?.attributes?.formats?.large?.url)} 800w`"
                sizes="(max-width: 500px) 500px, (max-width: 600px) 480px, 800px"
                :src="strapiImageUrl(hero.image?.data?.attributes?.formats?.large?.url)"
                alt="hero"
                :width="hero.image?.data?.attributes?.formats?.large.width"
                :height="hero.image?.data?.attributes?.formats?.large.height"
                class="mx-auto max-w-full rounded-t-xl rounded-tr-xl"
              >
            </div>
            <div class="absolute border rounded-t-xl rounded-tr-xl overflow-hidden shadow-xl max-w-[240px] md:max-w-[360px]  -right-24 top-20 z-10" v-if="hero.smallImage?.data?.attributes?.formats?.small?.url">
              <img
                :srcset="`${strapiImageUrl(hero.smallImage?.data?.attributes?.formats?.thumbnail?.url)} 500w, ${strapiImageUrl(hero.smallImage?.data?.attributes?.formats?.large?.url)} 800w`"
                sizes="(max-width: 500px) 500px, 800px"
                :src="strapiImageUrl(hero.smallImage?.data?.attributes?.formats?.small?.url)"
                alt="hero-small"
                :width="hero.smallImage?.data?.attributes?.formats?.large.width"
                :height="hero.smallImage?.data?.attributes?.formats?.large.height"
                class="mx-auto max-w-full"
              >
            </div>
            <div class="absolute -left-9 bottom-0 z-[-1]">
              <img :src="require('@/assets/dots.svg')" alt="dots" />
            </div>
            <div class="absolute -right-6 -top-6 z-[-1]">
              <img :src="require('@/assets/dots.svg')" alt="dots" />
            </div>
          </div>
        </div>
      </div>
      <div v-else style="min-height: 400vh;" />
    </Transition>
  </section>
</template>

<style lang="scss" scoped>
@tailwind components;
@layer components {
  .hero {
    @apply relative flex justify-center items-center overflow-hidden bg-gradient-to-r from-40% from-primary to-primary-300 pt-[100px] md:pt-[120px] lg:pt-[140px]
  }
  .hero-title {
    @apply mb-6 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug lg:text-5xl lg:leading-[1.2]
  }
  .hero-description {
    @apply mx-auto mb-9 max-w-[600px] text-base font-medium text-white sm:text-lg sm:leading-[1.44]
  }
  .hero-btn {
    @apply inline-flex items-center justify-center rounded-xl bg-green px-7 py-[14px] text-center text-base font-medium shadow hover:bg-green-400
  }
}
</style>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import { strapiImageUrl } from '@/utils';

@Component({
  props: {
    hero: {
      type: Object,
    },
  },
})
export default class HomeHero extends ComponentBase {
  declare public hero: any;

  public strapiImageUrl = strapiImageUrl;

  // eslint-disable-next-line class-methods-use-this
  public scrollToContact() {
    const element = document.querySelector('#footer-contact-header');
    if (element) {
      const top = element.getBoundingClientRect().top + window.scrollY - 10;
      window.scroll({
        top,
        behavior: 'smooth',
      });
    }
  }
}
</script>
