import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex w-full justify-center items-center bg-surface-ground"
}
const _hoisted_2 = { class: "container flex flex-wrap py-10" }
const _hoisted_3 = { class: "w-full px-20" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "flex align-center justify-center" }
const _hoisted_6 = {
  key: 0,
  class: "container markdown my-10"
}
const _hoisted_7 = { class: "w-full md:w-auto flex flex-col align-center justify-center text-center px-4 md:px-0" }
const _hoisted_8 = { class: "mb-3 text-3xl font-bold flex items-center justify-center" }
const _hoisted_9 = {
  key: 0,
  class: "inline-block mr-2 bg-amber-500 text-white text-xs font-medium px-2.5 py-0.5 rounded"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "max-w-[600px]" }
const _hoisted_12 = { class: "px-4 pt-4 md:pt-0 px-12 md:px-0 text-center" }
const _hoisted_13 = ["src", "alt"]
const _hoisted_14 = {
  key: 1,
  class: "h-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, {
      "enter-from-class": "opacity-0",
      "enter-active-class": "transition duration-700"
    }, {
      default: _withCtx(() => [
        (_ctx.description && !_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("section", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", {
                    class: "text-center",
                    innerHTML: _ctx.marked(_ctx.description ?? '')
                  }, null, 8, _hoisted_4)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_Transition, {
        "enter-from-class": "opacity-0",
        "enter-active-class": "transition duration-700"
      }, {
        default: _withCtx(() => [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productFeatures, (feature) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: feature.id,
                    class: _normalizeClass(["flex flex-wrap justify-center md:flex-nowrap pb-36", { 'flex-row-reverse': feature.imagePosition === 'right' }])
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("h3", _hoisted_8, [
                        (feature.badge)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(feature.badge), 1))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", null, _toDisplayString(feature.title ?? feature.Title), 1)
                      ]),
                      _createElementVNode("p", {
                        innerHTML: _ctx.marked(feature.content ?? '')
                      }, null, 8, _hoisted_10)
                    ]),
                    (feature.image?.data?.attributes?.formats)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(["md:max-w-[60%] md:min-w-[60%] flex flex-col justify-center items-center", { 'md:pr-10': feature.imagePosition === 'right', 'md:pl-10': feature.imagePosition !== 'right' }])
                        }, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("img", {
                                src: _ctx.strapiImageUrl(feature.image?.data?.attributes?.formats?.large?.url ?? feature.image?.data?.attributes?.formats?.medium?.url ?? feature.image?.data?.attributes?.formats?.small?.url ?? feature.image?.data?.attributes?.formats?.thumbnail?.url),
                                alt: feature.title ?? feature.Title,
                                class: "max-w-full inline-block max-h-96 border rounded-xl shadow-xl"
                              }, null, 8, _hoisted_13)
                            ])
                          ])
                        ], 2))
                      : _createCommentVNode("", true)
                  ], 2))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_14))
        ]),
        _: 1
      })
    ])
  ]))
}