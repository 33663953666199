import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex align-center justify-center" }
const _hoisted_2 = { class: "container markdown my-10" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "h-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, {
        "enter-from-class": "opacity-0",
        "enter-active-class": "transition duration-700"
      }, {
        default: _withCtx(() => [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "px-4 sm:px-0",
                innerHTML: _ctx.marked(_ctx.content)
              }, null, 8, _hoisted_3))
            : (_openBlock(), _createElementBlock("div", _hoisted_4))
        ]),
        _: 1
      })
    ])
  ]))
}