import { inject } from 'vue';
import { Vue } from 'vue-class-component';

import { useStore } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import { marked } from 'marked';
import Http from './http';
import AppState from './store/modules/app-state';
import { Locale } from './store/modules/interfaces';

// Register the router hooks with their names
export type { PropType } from 'vue';
export { Options as Component } from 'vue-class-component';

export class ComponentBase extends Vue {
  // definitely injected
  protected readonly http = inject<Http>('http') as Http;

  protected readonly vuex = useStore();

  protected get strapi() {
    return this.http.strapi;
  }

  protected get AppState() {
    return getModule(AppState, this.vuex);
  }

  // eslint-disable-next-line class-methods-use-this
  public marked(input: string) {
    return marked.parse(input, { breaks: true });
  }

  protected async initLocales() {
    const { data: locales } = await this.strapi.get<Locale[]>('/i18n/locales');
    this.AppState.setLocales(locales);
    let defaultLocale = locales?.find((l) => !!l.isDefault) ?? { code: 'en', name: 'English', isDefault: true };
    // Parse it from url if we have it on url
    if (this.$route.params.locale) {
      defaultLocale = locales?.find((l) => l.code === this.$route.params.locale) ?? { code: 'en', name: 'English', isDefault: true };
    }
    this.AppState.setCurrentLocale(defaultLocale);
  }

  protected async initCurrentLocale() {
    if (!this.AppState.currentLocale?.code) {
      console.error('No current locale set to app state');
      return;
    }
    const [{ data: navigation }, { data: footerNavigation }, { data: global }] = await Promise.all([
      this.strapi.get<any[]>('/navigation/render/main-navigation', {
        params: {
          locale: this.AppState.currentLocale?.code,
          type: 'TREE',
          orderBy: 'order',
        },
      }),
      this.strapi.get<any[]>('/navigation/render/footer-navigation', {
        params: {
          locale: this.AppState.currentLocale.code,
          type: 'TREE',
          orderBy: 'order',
        },
      }),
      this.strapi.get<any>('/global', { params: { locale: this.AppState.currentLocale.code } }),
    ]);
    this.AppState.setNav(navigation);
    this.AppState.setFootNav(footerNavigation);
    this.AppState.setGlobal(global.data?.attributes ?? {});
    document.documentElement.setAttribute('lang', this.AppState.currentLocale.code);
  }

  // eslint-disable-next-line class-methods-use-this
  protected setMetaInfo(title: string, description: string) {
    const descEl = document.querySelector('head meta[name="description"]');
    const titleEl = document.querySelector('head title');
    descEl?.setAttribute('content', description ?? 'Larva.io');
    titleEl!.textContent = title ?? 'Larva.io';
  }
}
